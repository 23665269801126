<template>
  <div class="container-fluid">
    <div class="row pt-3">
      <div class="col-2">
        <el-select v-model="selUserLogin" filterable placeholder="Select">
          <el-option
              v-for="item in usersList"
              :key="item.id"
              :label="item.text"
              :value="item.id"
          />
        </el-select>
      </div>
      <div class="col-10">
        <el-button @click="addUser">{{ $t('app.public.add') }}</el-button>
      </div>
    </div>
    <div class="row pt-3">
      <div class="col">
        <el-table :data="data" style="width: 100%">
          <el-table-column prop="user_login" :label="$t('app.login')"/>
          <el-table-column prop="bill_name" :label="$t('invisibleBets.bill_name_id')"/>
          <el-table-column prop="invisible_bets_date" :label="$t('invisibleBets.date_masking')"/>
          <el-table-column :label="$t('app.public.action')">
            <template #default="scope">
              <el-button
                  size="small"
                  type="danger"
                  @click="delUser(scope.row)"
              >{{ $t('app.public.del') }}</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref, inject, computed, onMounted, provide, watch} from "vue";
import InvisibleBetsService from "../../services/invisible_bets.service";

const $t = inject('$t')
const set_result = inject('set_result')

const usersList=ref([])
const selUserLogin=ref('')

const data=ref([])

function addUser()
{
  InvisibleBetsService.addUser(selUserLogin.value).then(
    (response) => {
      getUsersList()
      getMaskUsersList()
      selUserLogin.value=''
    },
    (error) => {
      set_result(error.response.status, error)
    }
  )
}

function delUser(row)
{
  InvisibleBetsService.delUser(row.user_login).then(
    (response) => {
      getUsersList()
      getMaskUsersList()
    },
    (error) => {
      set_result(error.response.status, error)
    }
  )
}

function getUsersList() {
  InvisibleBetsService.getUsersList().then(
    (response) => {
      usersList.value=response.data
    },
    (error) => {
      set_result(error.response.status, error)
    }
  )
}

function getMaskUsersList() {
  InvisibleBetsService.getMaskUsersList().then(
      (response) => {
        data.value=response.data
      },
      (error) => {
        set_result(error.response.status, error)
      }
  )
}

onMounted(() => {
  getUsersList()
  getMaskUsersList()
})
</script>
