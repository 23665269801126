import axios from 'axios';

const API_URLA = process.env.VUE_APP_APIA+'invisibleBets/';

class InvisibleBetsService {
  getUsersList() {
    return axios.post(API_URLA + 'getUsersList/');
  }
  getMaskUsersList() {
    return axios.post(API_URLA + 'getMaskUsersList/');
  }
  addUser(user_login) {
    return axios.post(API_URLA + 'saveUser/', {user_login: user_login, status: 'true'});
  }
  delUser(user_login) {
    return axios.post(API_URLA + 'saveUser/', {user_login: user_login, status: 'false'});
  }
}

export default new InvisibleBetsService();
